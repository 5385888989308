import React , {useState} from 'react';
import axios from 'axios';
import { Button, Container, Paper, Stack, Typography, TextField, CircularProgress, TableContainer, TableBody, TableHead, Table, TableCell, TableRow, Alert } from '@mui/material';
import './main.css';

const App = () => {
    const [state, setState] = useState(0);
    const [data, setData] = useState([]);
    const onClickPost = async () => {
        setState(1);
        const query = document.getElementById('text').value;
        axios.post(
            process.env.REACT_APP_SURVEYROBOT_BACKEND_URL,
            {
                'query': query
            }
        ).then((res) => {
            if(res.status !== 200){
                setState(-1);
            } else {
                setState(0);
                setData(res.data.papers);
            }
        }).catch((e) => {
            setState(-1);
        });
    }
    return (
        <Container maxWidth='lg'>
            <div className='titlebox'>
                <img src='robot.png' alt='logo' style={{ height: '6em' }}/>
                <Typography comonent='h1' variant='h2' sx={{ mx: 2 }}>SurveyRobot</Typography>
                <Typography comonent='h4' sx={{ mb: 1 }}>surveys for you.</Typography>
            </div>
            <Paper variant='outlined' sx={{ my: 3, p: 3 }}>
                <Typography component='h4'>Paste a manuscript you are writing or its .bib or .bbl file.</Typography>
            </Paper>
            <Alert severity='info' sx={{ my: 3 }}>Tips: You can copy your manuscript by opening the .pdf file, select all (Ctrl-A), and copy them (Ctrl-C).</Alert>
            <Alert severity='info' sx={{ my: 3 }}>Tips: A manuscript and its bib file invokes different algorithms. Try both.</Alert>
            <Alert severity='info' sx={{ my: 3 }}>Tips: If your manuscript is not in English, <a href='https://www.deepl.com/translator' target='_blank' rel='noreferrer'>machine-translate</a> it and paste the translated version.</Alert>
            <TextField id='text' fullWidth multiline rows={20} variant='outlined' placeholder='Paste a manuscript you are writing or its .bib or .bbl file, e.g.,
___
Word embeddings are one of the most fundamental technologies used in natural language processing.
Existing word embeddings are high-dimensional and consume considerable computational resources.
...
___
or
___
@inproceedings{sato2022word,
author = {Ryoma Sato and
...
___
or
___
\bibitem[{Sato(2022)}]{sato2022word}
Ryoma Sato. 2022.
...
___' />
        <Stack display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3, height: 40 }}>
            {state === 1 && <CircularProgress />}
            {state !== 1 && <Button variant='contained' onClick={onClickPost}>Submit</Button>}
        </Stack>
        {
            data.length > 0 && 
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Paper & Venue</TableCell>
                            <TableCell align="right">Year</TableCell>
                            <TableCell align="right">Cited by</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((row) => (
                                <TableRow key={row[5]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        <div className='papertitle'>{row[0]}</div>
                                        <div className='papervenue'>
                                            {row[1]}
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">{row[2]}</TableCell>
                                    <TableCell align="right">{row[3]}</TableCell>
                                </TableRow>                            
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        }
            <Paper variant='outlined' sx={{ mt: 20, p: 3 }}>
                <Typography component='h4'>This service uses Semantic Scholar Open Research Corpus under the ODC-BY license.</Typography>
                <Typography component='h4'>Waleed Ammar et al. Construction of the Literature Graph in Semantic Scholar. NAACL 2018.</Typography>
            </Paper>
            <Stack spacing={2} direction="row" justifyContent="center">
                <Typography component='h4' sx={{ mt: 5 }}>Contact：<a href='https://twitter.com/joisino_' target='_blank' rel='noreferrer'>@joisino_</a> / <a href='https://twitter.com/joisino_en' target='_blank' rel='noreferrer'>@joisino_en</a> / <a href='https://joisino.net' target='_blank' rel='noreferrer'>joisino.net</a></Typography>
            </Stack>
        </Container>
    );
};

export default App;